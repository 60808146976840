import { Input, Select } from '@/molecules';

import { provincias } from '@/lib/utils';

import type { CustomerDirectionForm } from '@/lib/utils';
import type { FormikErrors } from 'formik';

export const DirectionFormFields = ({
  errors,
}: {
  errors: FormikErrors<CustomerDirectionForm>;
}) => (
  <>
    <div className="mb-4 grid grid-cols-1 gap-x-5 gap-y-2 md:grid-cols-2">
      <Input
        name="shippingStreet"
        placeholder="Dirección *"
        id="shippingStreet"
        testid="shipping_street"
        error={errors.shippingStreet}
        className="grid-cols-1"
      />

      <Input
        name="shippingApartment"
        placeholder="Más información (opcional)"
        id="shippingApartment"
        testid="shipping_apartment"
        error={errors.shippingApartment}
      />

      <Input
        name="shippingCity"
        placeholder="Ciudad *"
        id="shippingCity"
        testid="shipping_city"
        error={errors.shippingCity}
      />

      <Select
        name="shippingProvince"
        placeholder="Provincia *"
        id="shippingProvince"
        testid="shipping_province"
        options={provincias}
        error={errors.shippingProvince}
      />

      <Input
        name="shippingPostCode"
        placeholder="Código postal *"
        id="shippingPostCode"
        testid="shipping_cp"
        error={errors.shippingPostCode}
      />

      <Input
        name="shippingPhone"
        placeholder="Teléfono *"
        id="shippingPhone"
        testid="shipping_phone"
        error={errors.shippingPhone}
      />
    </div>

    <p className="u-body u-body--s">* Campos obligatorios</p>
  </>
);
